<template>
	<div>
		<section v-if="prettyError">
			<AlertMessage
				:component="{
					content: {
						messageText: prettyError,
					},
				}"
				class="margin-bottom-l"
			/>
			<div v-if="prettyError">
				<TnButton @click="tryAgain">Prøv på nytt</TnButton>
			</div>
		</section>
		<section
			v-else
			class="address-property-search clearfix"
			:class="{ dark }"
		>
			<div class="address-property-search__fields">
				<TnTextField
					:label="content?.propertyIdLabel || 'Gårdsnummer'"
					:placeholder="content?.propertyIdPlaceholder || 'GNR'"
					autocomplete="off"
					class="address-property-search__fields--field margin-bottom-l"
					name="propertyId"
					v-model="formFields.propertyId"
					:dark="dark"
					required
				/>
				<TnTextField
					:label="content?.propertyPartNumberLabel || 'Bruksnummer'"
					:placeholder="content?.propertyPartNumberPlaceholder || 'BNR'"
					autocomplete="off"
					class="address-property-search__fields--field margin-bottom-l"
					name="propertyPartNumber"
					v-model="formFields.propertyPartNumber"
					:dark="dark"
					required
				/>
			</div>
			<div>
				<TnLabel
					:dark="dark"
					size="s"
				>
					{{ content?.municipalityLabel || "Kommune" }}
				</TnLabel>
				<TnInputText
					:placeholder="content?.municipalityPlaceholder || 'F.eks. Oslo'"
					:suggestions="suggestionsAsText"
					@update:modelValue="handleInput"
					@clearSuggestions="clearSuggestions"
					@suggestionSelected="suggestionSelected"
					v-model="formFields.municipalName"
					autocomplete="off"
					class="search-query"
					name="municipalName"
					:dark="dark"
					:loading="loading"
					:hide-details="true"
					@input="handleFocus"
					size="s"
				/>
				<TnSupportText
					:dark="dark"
					size="m"
				/>
			</div>
			<div
				v-if="additionalFieldsRequired"
				class="margin-top-m"
			>
				<TnTextField
					:label="content?.leaseHoldNumberLabel || 'Festenummer'"
					:placeholder="content?.leaseHoldNumberLabel || 'Festenummer (FNR)'"
					autocomplete="off"
					name="leaseHoldNumber"
					v-model="formFields.leaseHoldNumber"
					:dark="dark"
				>
					<template #info> Dersom din adresse ikke har festenummer skal du skrive 0 </template>
				</TnTextField>
				<TnTextField
					:label="content?.sectionNumberLabel || 'Undernummer'"
					:placeholder="content?.sectionNumberPlaceholder || 'Undernummer (UNR)'"
					autocomplete="off"
					name="sectionNumber"
					v-model="formFields.sectionNumber"
					:dark="dark"
					class="margin-top-m margin-bottom-l"
				>
					<template #info> Dersom din adresse ikke har undernummer skal du skrive 0 </template>
				</TnTextField>
			</div>
			<TnSupportText
				v-if="additionalText"
				:dark="dark"
				warning
				size="l"
				class="margin-bottom-l margin-top-m"
			>
				{{ additionalText }}
			</TnSupportText>
			<div class="submit-wrapper margin-top-l">
				<div class="flex-col">
					<TnButton
						text="Søk"
						size="m"
						:loading="submitting"
						:disabled="submitting || !haveRequiredFields"
						block
						iconRight="right"
						:dark="dark"
						@click.prevent="handleSubmit"
					/>
					<div>
						<slot />
					</div>
				</div>
				<TnSupportText class="margin-top-m">
					<TnParagraph
						:dark="dark"
						size="xs"
						>Har du gateadresse?
						<a
							href="javascript:"
							class="reverse-underline"
							@click="$emit('changeComponent', 'addressSearch')"
							>Søk her</a
						>.</TnParagraph
					>
				</TnSupportText>
				<RichText
					v-if="content?.textBelow"
					:text="content?.textBelow"
					class="margin-top-m"
				/>
			</div>
		</section>
	</div>
</template>

<script>
import { isMinimumDifferent, formatAddress } from "~/components/broadband-consumer/AddressSearch/addressSearchModel.js";
import SupportsDarkMode from "~/mixins/platform/SupportsDarkMode.js";
import axios from "axios";
import TelenorIdUserMixin from "~/mixins/platform/TelenorIdUserMixin.js";
import telenorid from "~/telenorid/telenorid";
import FormatToPrettyError from "~/helpers/formatting/formatToPrettyError";
import {mapState} from "pinia";

const PROPERTY_SEARCH_URL = "/api/address-search/property/";

export default defineNuxtComponent({
	name: "AddressPropertySearch",
	mixins: [SupportsDarkMode, TelenorIdUserMixin],

	props: {
		content: {
			type: Object,
			default: () => {},
		},
		dark: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			user: null,
			suggestions: [],
			formFields: {
				propertyId: "",
				propertyPartNumber: "",
				municipalName: "",
				sectionNumber: "",
				leaseHoldNumber: "",
			},
			previousSearch: "",
			requiredFields: ["propertyId", "propertyPartNumber", "municipalName"],
			additionalFieldsRequired: false,
			loading: false,
			submitting: false,
			additionalText: "",
			suggestionsBaseUrl: "/api/address-search/municipality/?q=",
			searchTimeout: null,
			showSuggestions: false,
			prettyError: null,
		};
	},

	async created() {
		this.user = await telenorid.getOrLoginUser();
		if (this.cadastralAddress) {
			Object.keys(this.formFields).forEach((k) => (this.formFields[k] = this.cadastralAddress[k] ?? ""));
			this.setDefaults();
			this.additionalFieldsRequired = true;
		}
	},

	computed: {
    ...mapState(useAddressStore, 'cadastralAddress'),

		suggestionsAsText() {
			if (!this.suggestions.length && this.showSuggestions && this.query) {
				return ["Ingen treff"];
			} else if (this.suggestions.length && this.showSuggestions) {
				return this.suggestions?.map((suggestion) => formatAddress(suggestion));
			} else {
				return [];
			}
		},
		trimmedForm() {
			return Object.keys(this.formFields).reduce(
				(form, field) => ({ ...form, [field]: this.formFields[field].trim() }),
				{},
			);
		},
		allFieldsExhausted() {
			return Object.values(this.trimmedForm).every((val) => !!val);
		},
		haveRequiredFields() {
			return this.requiredFields.map((field) => this.formFields[field]).every((val) => !!val);
		},
	},

	methods: {
		handleInput(input) {
			if (this.searchTimeout) clearTimeout(this.searchTimeout);
			this.formFields.municipalName = input;
			if (this.formFields.municipalName.length < 3) {
				this.suggestions = [];
				return;
			}
			if (!this.formFields.municipalName) return;
			// If at least 3 characters have changed since the last search performed (excluding spaces), perform an immediate search
			if (isMinimumDifferent(this.previousSearch, this.formFields.municipalName, 3)) {
				this.getSuggestions();
			} else {
				this.searchTimeout = setTimeout(() => {
					if (this.formFields.municipalName !== "") {
						this.getSuggestions();
					}
				}, 1000);
			}
		},

		clearSuggestions() {
			this.showSuggestions = false;
			clearTimeout(this.searchTimeout);
		},

		setDefaults() {
			//We want these field to default to 0, and be part of the search only if additionalFields are required
			this.formFields.leaseHoldNumber = this.formFields.leaseHoldNumber ? this.formFields.leaseHoldNumber : "0";
			this.formFields.sectionNumber = this.formFields.sectionNumber ? this.formFields.sectionNumber : "0";
		},

		async getSuggestions() {
			this.previousSearch = this.formFields.municipalName;
			try {
				const { data } = await axios.get(this.suggestionsBaseUrl + encodeURIComponent(this.formFields.municipalName));
				if (data.municipalities) {
					this.suggestions = data.municipalities;
				}
			} catch (e) {
				console.error(e);
				this.prettyError = FormatToPrettyError.messageAndCodes(e);
			}
		},

		suggestionSelected(idx) {
			this.clearSuggestions();
			this.formFields.municipalName = this.suggestions[idx];
			clearTimeout(this.searchTimeout);
		},

		async handleResponse(data) {
			if (data.length === 1) {
				await useAddressStore().fetchAddressAndApartments({
					addressId: data[0].addressId,
					isLoggedIn: !!this.user,
				});
				this.$emit("addressFound");
			} else if (data.length > 1) {
				if (this.allFieldsExhausted) this.additionalText = this.content.inconclusiveSearchText;
				else {
					this.additionalFieldsRequired = true;
					this.setDefaults();
					this.additionalText = this.content?.multipleResultsText || "";
				}
			} else {
				this.additionalText = this.content?.noResultsText || "Ingen treff";
			}
		},

		async handleSubmit() {
			this.suggestions = [];
			this.submitting = true;
			this.clearSuggestions();
			try {
				const { data } = await axios.post(PROPERTY_SEARCH_URL, {
					...this.trimmedForm,
					allFieldsExhausted: this.allFieldsExhausted,
				});
				await this.handleResponse(data);
			} catch (e) {
				console.error(e);
				this.prettyError = FormatToPrettyError.messageAndCodes(e);
			}
			this.submitting = false;
		},
		handleFocus() {
			this.showSuggestions = true;
		},
		tryAgain() {
			this.suggestions = [];
			this.submitting = false;
			this.clearSuggestions();
			this.prettyError = null;
		},
	},
});
</script>

<style lang="scss" scoped>
.submit-wrapper {
	display: inline-block;
}

.address-property-search {
	padding-bottom: -16px;

	.address-property-search__fields {
		display: flex;
		justify-content: space-between;
		gap: $spacing-l;
		@include breakpoint(mobile) {
			flex-direction: column;
			gap: 0;
		}
		.address-property-search__fields--field {
			width: 100%;
		}
	}

	&.dark {
		color: #fff;
	}

	.search-query {
		:deep([name="municipalName"]) {
			text-transform: capitalize;

			&::placeholder {
				text-transform: none;
			}
		}

		:deep(.suggestions) {
			top: 60px;
		}
	}

	:deep([name="sectionNumber"]) {
		text-transform: uppercase;

		&::placeholder {
			text-transform: none;
		}
	}
}
</style>
